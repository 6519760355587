import React, { lazy, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));

// import context

const ProtectedRoute = ({ user, children }) => {
  if (!user) {
    console.log("Redirecting to login page...");
    return <Redirect to="/login" />;
  }

  return children;
};

function App() {
  const [user, setUser] = useState(localStorage.getItem("token"));

  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login">
            <Login setUser={setUser} />
          </Route>
          <ProtectedRoute user={user}>
            {/* Place new routes over this */}
            <Route path="/app" component={Layout} />
          </ProtectedRoute>
        </Switch>
      </Router>
    </>
  );
}

export default App;
